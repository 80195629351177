<template>
    <div class="w-100 pb-4 additional-detail">

        <div class="w-100">
          <div
            class="container-fluid tab-panel"
            style="margin-top: 10px;"
          >
            <div
              class="card w-100"
              style="box-shadow: rgb(233 235 239) 1px 2px 13px 5px;"
            >
              <div class="card-body p-4 border-bottom box-shadow">
                <div
                    class="card-header font-large ProximaNovaBold"
                    style="
                    padding: 0;
                    background: transparent;
                    border: 0px !important;
                  "
                  >
                    Additional Details
                </div>
              </div>
              <div class="card-body p-4">
              <div
                class="row align-items-center additonal-detail pt-5"
                v-for="(detail, index) in additionalDetails"
                :key="index"
              >
                <div class="col-lg-2 col-md-2 col-12">
                  <h6 class="ProductNew">{{ detail.name }}</h6>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="d-flex justify-center">
                    <div class="w-100">
                      <w-dropdown
                        :options="detail.dropdownList"
                        :placeHolder="'Select'"
                        :labelText="'Product'"
                        class=""
                        @input="selectedKPi($event, index)"
                      ></w-dropdown>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-3 col-12">
                  <div class="date-wraper d-flex w-100">
                    <div class="d-flex align-items-center w-100">
                      <w-datepicker
                        :placeHolder="'Select'"
                        :labelText="'Date'"
                        class=""
                        :selectedDate="detail.date"
                        @getDate="selectedProduct($event,index)"
                      ></w-datepicker>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-12">
                  <div>
                    <b-form-input
                      class="input-wrapper"
                      v-model="detail.value"
                      type="number"
                      placeholder="Value in numbers"
                      oninput="value=value.replace(/[^0-9]/g,'');"
                    ></b-form-input>
                  </div>
                </div>
              </div>
                <!--<div class="row mb-5">
                  <div class="col-md-3 d-flex align-items-center">
                    <div class="detail_title">Product</div>
                  </div>
                  <div class="col-md-3 col-xs-12 production-section">
                    <w-dropdown
                      :options="productDropdown"
                      :placeHolder="'Select'"
                      :labelText="'Product'"
                      class="mr-3"
                      @input="selectedProduct($event)"
                    ></w-dropdown>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <w-datepicker
                      :options="productDropdown"
                      :placeHolder="'Select'"
                      :labelText="'Date'"
                      class="mr-3"
                      @input="selectedProduct($event)"
                    ></w-datepicker>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <b-form-input
                      type="text"
                      class="form-control c-form-input"
                      id="exampleInput"
                      placeholder="Values in numbers"
                      name="value_in_numbers"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-md-3 col-xs-12 d-flex align-items-center">
                    <div class="detail_title">Distribution</div>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <w-dropdown
                      :options="productDropdown"
                      :placeHolder="'Select'"
                      :labelText="'Product'"
                      class="mr-3"
                      @input="selectedProduct($event)"
                    ></w-dropdown>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <w-datepicker
                      :options="productDropdown"
                      :placeHolder="'Select'"
                      :labelText="'Date'"
                      class="mr-3"
                      @input="selectedProduct($event)"
                    ></w-datepicker>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <b-form-input
                      type="text"
                      class="form-control c-form-input"
                      id="exampleInput"
                      placeholder="Values in numbers"
                      name="value_in_numbers"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-md-3 col-xs-12 d-flex align-items-center">
                    <div class="detail_title">Consumer Promo</div>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <w-dropdown
                      :options="productDropdown"
                      :placeHolder="'Select'"
                      :labelText="'Product'"
                      class="mr-3"
                      @input="selectedProduct($event)"
                    ></w-dropdown>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <w-datepicker
                      :options="productDropdown"
                      :placeHolder="'Select'"
                      :labelText="'Date'"
                      class="mr-3"
                      @input="selectedProduct($event)"
                    ></w-datepicker>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <b-form-input
                      type="text"
                      class="form-control c-form-input"
                      id="exampleInput"
                      placeholder="Values in numbers"
                      name="value_in_numbers"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 col-xs-12 d-flex align-items-center">
                    <div class="detail_title">Advertising</div>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <w-dropdown
                      :options="productDropdown"
                      :placeHolder="'Select'"
                      :labelText="'Product'"
                      class="mr-3"
                      @input="selectedProduct($event)"
                    ></w-dropdown>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <w-datepicker
                      :options="productDropdown"
                      :placeHolder="'Select'"
                      :labelText="'Date'"
                      class="mr-3"
                      @input="selectedProduct($event)"
                    ></w-datepicker>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <b-form-input
                      type="text"
                      class="form-control c-form-input"
                      id="exampleInput"
                      placeholder="Values in numbers"
                      name="value_in_numbers"
                    ></b-form-input>
                  </div>
                </div>-->
              </div>
          </div>
          </div>
        </div>
    </div>
</template>

<script>
import Dropdown from "@/widgets/Dropdown.vue";
import DatePicker from "@/widgets/DatePicker.vue";

export default {
    name: 'AdditionalDetails',
    components: {
      "w-dropdown": Dropdown,
      "w-datepicker": DatePicker,
    },
    data() {
      return {
        additionalDetails: [
        {
          name: "Product",
          product: "",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          selectedValue: "",
          date: new Date(),
          value: "",
        },
        {
          name: "Distribution",
          dropdownList: [
            { text: "Modern Trade", id: "Modern Trade" },
            { text: "Traditional Trade", id: "Traditional Trade" },
            { text: "Online/Market Places", id: "Online/Market Places" },
          ],
          selectedValue: "",
          product: "",
          date: new Date(),
          value: "",
        },
        {
          name: "Consumer Promo",
          dropdownList: [
            { text: "New Consumer Promo Campaigns", id: "New Consumer Promo Campaigns" },
            { text: "Total Consumer Promos", id: "Total Consumer Promos" },
            { text: "Trade Promos", id: "Trade Promos" },
          ],
          selectedValue: "",
          product: "",
          date: new Date(),
          value: "",
        },
        {
          name: "Advertising",
          dropdownList: [
            { text: "New Messaging Platforms", id: "New Messaging Platforms" },
            { text: "New Campaign Lunches", id: "New Campaign Lunches" },
            { text: "New Celebrity endorsements", id: "New Celebrity endorsements" },
          ],
          selectedValue: "",
          product: "",
          date: new Date(),
          value: "",
        },
      ],
        productDropdown: [
          { text: "New product launch", id: 1 }
        ],
        selectProduct: ''
      }
    },
    methods: {
      selectedProduct(e,index){
      this.additionalDetails[index].date = e
    },
      selectedKPi(e, index) {
      if (this.additionalDetails[index].selectedValue != e) {
        this.additionalDetails[index].selectedValue = e;
        console.log(e);
      }
    },
    }
}
</script>

<style scoped>
.detail_title {
  font-family: ProximaNovaRegular; 
  font-size: 20px;
  font-weight: bold;
}
.ProductNew {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.date-wraper {
  height: 60px;
  background-color: #eff5ff;
}
.input-wrapper {
  height: 60px;
  font-size: 16px;
  padding-left: 21px;
}
input {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
}
input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
}

input[type="number"] {
  outline: none;
  box-shadow: none;
  font-family: ProximaNovaBold;
  font-size: 16px;
}
#exampleInput {
  height: 54px;
  border: none;
  margin: 0px 1px 0px 0;
  object-fit: contain;
  padding: 18px 30px 18px 16px;;
  border-radius: 3px;
  color: #222a37;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-size: 24px;
  background-color: #eff5ff;
}
.box-shadow {
  box-shadow: rgb(233 235 239) 2px 5px 9px 0px;
}
.additional-detail{
  overflow: visible; padding: 0 50px;
}
@media screen and (max-width: 640px){
  .additional-detail{
    padding: 0 15px !important;
  }
  .production-section {
      margin-bottom: 5px;
  }
}
@media screen and (max-width: 768px){
  .additional-detail{
    padding: 0 15px !important;
  }
  .production-section {
      margin-bottom: 5px;
  }
}
</style>
