<template>
    <div class="page-wrapper chiller-theme" style="margin-top: 70px">
       <main class="page-content w-99">
          <div class="container-fluid row">
            <ul
              class="breadcrumb ProximaNovaRegular col"
              style="margin-left: 52px; margin-top: 12px"
            >
              <li>
                <span>Sales Statistics</span>
              </li>
              <li>
                <span>Long term level</span>
              </li>
              <li>
                <span>Future prediction</span>
              </li>
              <li class="ProximaNovaBold">Scenerio planning</li>
            </ul>
            <div
              class="col-md-2 colo-lg-2 d-flex justify-center"
              style="height: 40%"
              v-if="activeTab === 'Additional Details'"
              >
                <div class="w-100 py-4">
                    <w-dropdown
                    :options="marketTypedropdown"
                    :placeHolder="'Select'"
                    :labelText="'Market type'"
                    class="mr-3"
                    textWidth="50%"
                    dropdownWidth="50%"
                    bgColor="#fff"
                    labelColor="#727988"
                    boxShadow="box-shadow: 0 0 8px 0 #c9ced5;"
                    ></w-dropdown>
                </div>
            </div>
          </div>

          <!-- tab -->
          <div
            class="container-fluid"
            >
            <w-tab
              :tabName="activeTab"
              :tabs="tabs"
              @activeTab="changeTab($event)"
            >
            </w-tab>
          </div>
        <!-- Digital -->
        <div class="w-100 pb-4 prediction-section"
          v-if="activeTab === 'Digital Media'"
        >

            <div class="w-100">
              <div
                class="container-fluid tab-panel"
                style="margin-top: 10px;"
              >
                <div
                  class="card w-100"
                  style="box-shadow: rgb(233 235 239) 1px 2px 13px 5px;"
                >
                  <div class="card-body p-4">
                    <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                        padding: 0;
                        background: transparent;
                        border: 0px !important;
                      "
                      >
                        Prediction
                        <span class="float-right text-danger text-normal ProximaNovaRegular">* Total growth is 100</span>
                        <div class="caption mt-3">
                          <span :class="breadcrumb.first !== '' &&
                            breadcrumb.second === '' && breadcrumb.third === ''? 'text-bold' : ''
                            ">{{breadcrumb.first}}</span>
                          <span v-if="breadcrumb.second" :class="breadcrumb.first !== '' &&
                            breadcrumb.second !== '' && breadcrumb.third === '' ? 'text-bold' : ''"><i class="fa fa-chevron-right mx-3"></i>{{breadcrumb.second}}</span>
                          <span v-if="breadcrumb.third" :class="breadcrumb.first !== '' &&
                            breadcrumb.second !== '' && breadcrumb.third !== '' ? 'text-bold' : ''"><i class="fa fa-chevron-right mx-3"></i>{{breadcrumb.third}}</span>
                        </div>
                    </div>
                  </div>
              </div>
              </div>
            </div>
              <div
                class="container-fluid tab-panel card__body_content pt-5"
              >
              <div class="row fix-width">
                <div class="col">
                  <div @click="toggleOpenButton(i)" class="newcard" :class="[i === activeGraph.activeFirst ? 'border border-dark' : 'disabled_card', 
                  i === activeGraph.activeFirst && info.data && info.data.length > 0 ? 'border_right_side' : '']"
                    v-for="(info, i) in data" :key="i">
                    <div class="mb-4">
                      <span class="slider_text">
                        <img
                          v-if="info.title === 'Instagram'" src="@/assets/Icons/insta.svg" alt=""
                          class="mr-2"
                        />
                        <img
                          v-if="info.title === 'DV360'" src="@/assets/Icons/bitmap.png" alt=""
                          class="mr-2"
                        />
                        <img
                          v-if="info.title === 'Linkedin'" src="@/assets/Icons/linkedin.svg" alt=""
                          class="mr-2"
                        />
                        <img
                          v-if="info.title === 'Twitter'" src="@/assets/Icons/twitter.png" alt=""
                          class="mr-2"
                        />
                        {{info.title}}
                      </span>
                      <span class="float-right slider_button_right">
                        <img
                          v-if="!showMap[i]"
                           @click="toggleOpenButton(i)"
                          class="eye-line"
                          src="@/assets/eye-close-line.svg"
                          alt=""
                        />
                        <img
                          v-else
                           @click="toggleCloseButton(i)"
                          class="eye-line"
                          src="@/assets/eye-line.png"
                          alt=""
                        />
                      </span>
                    </div>
                    <w-activitychart :sliderValue="info.value" :color="info.color"></w-activitychart>
                    <div class="divider"
                      v-if="i === activeGraph.activeFirst && data[activeGraph.activeFirst].data"
                      :style="activeGraph.activeSecond === 0 ? `height:0` : `height:`+ 336*activeGraph.activeSecond+`px;`"
                    ></div>
                  </div>
                </div>
                <div class="col">
                  <div v-if="activeGraph.activeFirst !== null">
                    <div @click="toggleOpenSubButton(index)" class="newcard d-block position-relative"
                      :style="activeGraph.activeFirst === 0 ? `top:0` : `top:`+ 336*activeGraph.activeFirst+`px;`"
                      :class="[index === activeGraph.activeSecond ? 'border border-dark border_left_side' : 'disabled_card',
                      index === activeGraph.activeSecond && infoData.data && infoData.data.length > 0 ? 'border_right_side' : '']"
                      v-for="(infoData, index) in data[activeGraph.activeFirst].data" :key="index">
                      <div class="mb-4">
                        <span class="slider_text">
                          <img
                            v-if="infoData.title === 'Instagram'" src="@/assets/Icons/insta.svg" alt=""
                            class="mr-2"
                          />
                          <img
                            v-if="infoData.title === 'DV360'" src="@/assets/Icons/bitmap.png" alt=""
                            class="mr-2"
                          />
                          <img
                            v-if="infoData.title === 'Linkedin'" src="@/assets/Icons/linkedin.svg" alt=""
                            class="mr-2"
                          />
                          <img
                            v-if="infoData.title === 'Twitter'" src="@/assets/Icons/twitter.png" alt=""
                            class="mr-2"
                          />
                          {{infoData.title}}
                        </span>
                        <span class="float-right slider_button_right">
                          <img
                            v-if="showMapSub[index]"
                            @click="toggleCloseSubButton(index)"
                            class="eye-line"
                            src="@/assets/eye-line.png"
                            alt=""
                          />
                          <img
                            v-else
                            @click="toggleOpenSubButton(index)"
                            class="eye-line"
                            src="@/assets/eye-close-line.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <w-activitychart :sliderValue="infoData.value" :color="infoData.color"></w-activitychart>
                    </div>
                    </div>
                </div>
                <div class="col">
                  <div v-if="activeGraph.activeSecond !== null">
                    <div class="newcard d-block position-relative"
                      :style="subsubStyle"
                      v-for="(infoData, index) in data[activeGraph.activeFirst].data[activeGraph.activeSecond].data" :key="index"
                      :class="index === 0 ? 'border_left_side' : ''">
                      <div class="mb-4">
                        <span class="slider_text">
                          {{infoData.title}}
                        </span>
                      </div>
                      <w-activitychart :sliderValue="infoData.value" :color="infoData.color"></w-activitychart>
                    </div>
                    </div>
                </div>
              </div>
              </div>
        </div>
        <w-scenarioplanning v-if="activeTab === 'Additional Details'"></w-scenarioplanning>
        <div class="float-right bottom_buttom">
          <button
            color="primary isi"
            class="btn-effect mr-5"
            size="button--size-m"
            :class="activeGraph.activeSecond !== null ? 'active' : ''"
            @click="handleClick()"
          >
            Predict<i
              class="fas fa-arrow-right ml-5 pl-4 d-flex"
              style="align-items: center"
            ></i>
          </button>
        </div>
       </main>
    </div>
</template>

<script>
import Tab from "@/components/Solutions/Tab.vue";
import ActivityChart from "@/components/Chart/ActivityChart.vue";
import AdditionalDetails from '@/pages/Scenario/AdditionalDetails.vue';
import Dropdown from "@/widgets/Dropdown.vue";

export default {
    name: 'ScenarioPlanning',
    components: {
      "w-tab": Tab,
      "w-activitychart": ActivityChart,
      "w-scenarioplanning": AdditionalDetails,
      "w-dropdown": Dropdown,
    },
    data() {
      return  {
        marketTypedropdown: [
            { text: "USA", id: "1" },
            { text: "INDIA", id: "2" },
            { text: "UK", id: "3" },
        ],
        breadcrumb: {
          first: 'Channel',
          second: '',
          third: ''
        },
        activeTab: "Digital Media",
        activeGraph: {
          activeFirst: null,
          activeSecond: null
        },
        tabs: [
          {
            category: "Digital Media",
          },
          {
            category: "Content",
          },
          {
            category: "Additional Details",
          }
        ],
        showMap: [],
        showMapSub: [],
        data: null,
        // [
        //   {
        //     id: 1,
        //     title: 'Instagram',
        //     value: 20,
        //     color: '#1877f2',
        //     data: [
        //       {
        //         id: 1,
        //         title: 'Engagement Campaigns',
        //         value: 25,
        //         color: '#b5a9ff',
        //         data: [
        //           {
        //             id: 1,
        //             title: 'Lead Ads',
        //             value: 50,
        //             color: '#b5a9ff'
        //           },
        //           {
        //             id: 2,
        //             title: 'Lead Ads',
        //             value: 41,
        //             color: '#ffdd7c'
        //           }
        //         ]
        //       },
        //       {
        //         id: 2,
        //         title: 'VIdeo Campaigns',
        //         value: 30,
        //         color: '#ffdd7c',
        //         data: [
        //           {
        //             id: 1,
        //             title: 'Lead Ads',
        //             value: 50,
        //             color: '#b5a9ff'
        //           },
        //           {
        //             id: 2,
        //             title: 'Lead Ads',
        //             value: 41,
        //             color: '#ffdd7c'
        //           }
        //         ]
        //       },
        //       {
        //         id: 3,
        //         title: 'Lead Ads',
        //         value: 41,
        //         color: '#67e8ff',
        //         data: [
        //           {
        //             id: 1,
        //             title: 'Lead Ads',
        //             value: 50,
        //             color: '#b5a9ff'
        //           },
        //           {
        //             id: 2,
        //             title: 'Lead Ads',
        //             value: 41,
        //             color: '#ffdd7c'
        //           }
        //         ]
        //       },
        //     ]
        //   },
        //   {
        //     id: 2,
        //     title: 'DV360',
        //     value: 50,
        //     color: '#dd2a7b',
        //     data: [
        //       {
        //         id: 1,
        //         title: 'Engagement Campaigns',
        //         value: 25,
        //         color: '#b5a9ff',
        //         data: [
        //           {
        //             id: 1,
        //             title: 'Lead Ads',
        //             value: 50,
        //             color: '#b5a9ff'
        //           },
        //           {
        //             id: 2,
        //             title: 'Lead Ads',
        //             value: 41,
        //             color: '#ffdd7c'
        //           }
        //         ]
        //       },
        //       {
        //         id: 2,
        //         title: 'VIdeo Campaigns',
        //         value: 30,
        //         color: '#ffdd7c',
        //         data: [
        //           {
        //             id: 1,
        //             title: 'Lead Ads',
        //             value: 50,
        //             color: '#b5a9ff'
        //           },
        //           {
        //             id: 2,
        //             title: 'Lead Ads',
        //             value: 41,
        //             color: '#ffdd7c'
        //           }
        //         ]
        //       },
        //       {
        //         id: 3,
        //         title: 'Lead Ads',
        //         value: 41,
        //         color: '#67e8ff',
        //         data: [
        //           {
        //             id: 1,
        //             title: 'Lead Ads',
        //             value: 50,
        //             color: '#b5a9ff'
        //           },
        //           {
        //             id: 2,
        //             title: 'Lead Ads',
        //             value: 41,
        //             color: '#ffdd7c'
        //           }
        //         ]
        //       },
        //     ]
        //   },
        //   {
        //     id: 3,
        //     title: 'Linkedin',
        //     value: 60,
        //     color: '#148f3a'
        //   },
        //   {
        //     id: 4,
        //     title: 'Twitter',
        //     value: 80,
        //     color: '#0077b5'
        //   },
        //   {
        //     id: 5,
        //     title: 'Instagram',
        //     value: 75,
        //     color: '#08a0e9'
        //   },
        // ],
        subdata: [
          {
            id: 1,
            title: 'Instagram',
            value: 41,
            color: '#ff4081',
            data: [
              {
                id: 1,
                title: 'Engagement Campaigns',
                value: 20,
                color: '#7c4dff'
              },
              {
                id: 2,
                title: 'VIdeo Campaigns',
                value: 30,
                color: '#ff5252'
              },
              {
                id: 3,
                title: 'Lead Ads',
                value: 50,
                color: '#e040fb',
                data:[
                  {
                    id:1,
                    title: 'Lead Ads',
                    value:50,
                    color: '#ffab40'
                  },
                  {
                    id:2,
                    title: 'Lead Ads',
                    value:41,
                    color: '#69f0ae'
                  }
                ]
              },
              {
                id:4,
                title: 'Click to website',
                value: 41,
                color: '#448aff'
              },
              {
                id:5,
                title: 'Video Campaigns',
                value: 30,
                color: '#69f0ae'
              },
            ]
          },
          {
            id: 2,
            title: 'DV360',
            value: 25,
            color: '#ffab40',             
          },
          {
            id: 3,
            title: 'Linkedin',
            value: 30,
            color: '#40c4ff'
          },
          {
            id: 4,
            title: 'Twitter',
            value: 50,
            color: '#7c4dff'
          },
          {
            id: 5,
            title: 'Instagram',
            value: 41,
            color: '#ff4081'
          },
        ]
      }
    },
    mounted() {
      const digitalData = this.$router.history.current.params.digitalData;
      console.log(digitalData, 'digitalDatadigitalData');
      let data = [];
      digitalData && digitalData.forEach((ele, index) => {
        data.push({
          id: index+1,
          title: ele.label,
          value: ele.value,
          color: ele.backgroundColor,
          data: this.subdata
        })
      });
      this.data = data;
    },
    computed: {
      subsubStyle() {
        let style;
        if(this.activeGraph.activeFirst === 0 && this.activeGraph.activeSecond === 0) {
          style = `top: 4px;`;
        } else {
          if((this.activeGraph.activeFirst > 0  && this.activeGraph.activeSecond === 0) || (this.activeGraph.activeFirst > 0  && this.activeGraph.activeSecond > 0)) {
            style = `top:`+ (336*(this.activeGraph.activeSecond+1) + 6)+`px;`;
          } else {
            // style = `top:`+ (336*(this.activeGraph.activeSecond+1) + 6)+`px;`;
          style = `top:`+ (334*(this.activeGraph.activeSecond) + 6)+`px;`;
          }
        }
        return style;
      }
    },
    methods: {
      changeTab(tabName) {
        this.activeTab = tabName;
      },
      toggleOpenButton(index) {
        if(!this.showMap[index]){
          this.activeGraph.activeFirst = index;
          this.showMap = [];
          this.showMap[index]=true
          this.breadcrumb.second = 'Ad type';
        }
        else{
          this.activeGraph.activeFirst = null;
          this.showMap[index] = false;
          this.breadcrumb.second = '';
        }
      },
      toggleCloseButton(index) {
        this.activeGraph.activeFirst = null;
        this.showMap[index] = false;
        this.breadcrumb.second = '';
      },
      toggleOpenSubButton(index) {
        if (!this.showMapSub[index]) {
          this.activeGraph.activeSecond = index;
          this.showMapSub = [];
          this.showMapSub[index] = true;
          this.breadcrumb.third = 'Campaign type ';  
        }else{
          this.activeGraph.activeSecond = null;
          this.showMapSub[index] = false;
          this.breadcrumb.third = '';  
        }
        
      },
      toggleCloseSubButton(index) {
        this.activeGraph.activeSecond = null;
        this.showMapSub = [];
        this.showMapSub[index] = false;
        this.breadcrumb.third = '';
      },
      handleClick() {
        this.$router.push("scenario-planning/planning-results");
      }
    }
}
</script>

<style scoped>
.w-99 {
  width: 99%;
}
.chart-container {
  background: transparent !important;
}
.caption {
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727988;

}
.newcard {
  width: 312px;
  height: 312px;
  margin: 24px;
  padding: 12px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #fff;
  margin: auto;
  margin-bottom: 24px;
}
.slider_text {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
}
.slider_button_right {
  background: #f3f3f3;
  padding: 5px;
  border-radius: 4px;
}
.text-normal {
  font-weight: normal;
  font-size: 16px;
}
.bottom_header {
  position: sticky;
  top: 100px;
  z-index: 8;
  background: #f2f5fa;
  box-shadow: rgb(233 235 239) 0px 9px 9px 0px;
}
.bottom_buttom {
  width: 100%;
  text-align: end;
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: #f2f5fa;
}
.btn-effect {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}
.btn-effect-valid {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect-valid i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover, .btn-effect.active {
  background-color: #050505;
  color: #f3f3f3;
}
.border_right_side::before {
  content: '';
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: 299px;
  top: 50%;
  width: 30%;
}
.border_left_side::after {
  content: '';
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: -30%;
  top: -65%;
  width: 25%;
}
.border-dark {
  border: 2px solid #000 !important;
}
.disabled_card {
  background: #dadbdd;
  opacity: 0.5;
}
.divider {
  border-right: 2px solid #000;
  top: -64%;
  display: block;
  left: 30.5%;
  position: relative;
}
.text-bold {
  font-weight: bold;
}
.card__body_content {
  overflow-y: auto;
  height: 85%;
  overflow-x: auto;
  box-shadow: rgb(233 235 239) -2px 4px 20px 11px;
  width: 98%;
}
.fix-width {
  max-width: 1600px;
  margin: auto;
  width: 1365px;
}
.prediction-section{
  overflow: hidden;
  height: 660px;
  padding: 0 50px;
}
@media screen and (max-width: 640px){
  .prediction-section{
    padding: 0 15px !important;
  }
}
@media screen and (max-width: 768px){
  .prediction-section{
    padding: 0 15px !important;
  }
}
</style>
