<template>
  <div class="chart-container">
  <p class="d-none">{{sliderValue}}</p>
    <round-slider
      :style="{
        '--handleColor': color,
      }"
      v-model="value"
      start-angle="90"
      end-angle="+360"
      line-cap="round"
      radius="120"
      class="slider__class"
      :editableTooltip="false"
      :change="sliderChange"
      :rangeColor="color"
      :pathColor="checkColor + '30'"
    />
  </div>
</template>

<script>
import RoundSlider from "vue-round-slider";

export default {
  name: "PieChart",
  components: {
    RoundSlider,
  },
  props: {
    sliderValue: {
      type: Number,
    },
    color: {
      type: String,
    },
  },
  data() {
    return {
      value: 0,
    };
  },
  beforeUpdate(){
    this.value = this.sliderValue
  },
  methods: {
    sliderChange(e) {
      this.value = e.value;
      this.$emit("sliderChanged", e.value);
    },
    
  },
  computed: {
    // value(){
    //   return this.sliderValue
    // },
    checkColor() {
      var data = this.color.split("");
      if (data[0] === "r" && data[1] === "g" && data[2] === "b") {
        var rgbColor = this.color.split("(")[1].split(")")[0];
        rgbColor = rgbColor.split(",");
        var hexColor = this.rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2])
        return hexColor;
      } else {
        return this.color;
      }
    },
    componentToHex() {
      return (c) => {
        var hex = parseInt(c).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };
    },
    rgbToHex() {
      return (r, g, b) => {
        return (
          "#" +
          this.componentToHex(r) +
          this.componentToHex(g) +
          this.componentToHex(b)
        );
      };
    },
  },
  created() {
    this.value = this.sliderValue;
  },
};
</script>

<style scoped>
.slider__class {
  margin: auto;
}
</style>
<style>
.rs-handle.rs-move {
  border: 4px solid #fff;
}
.rs-handle {
  background-color: var(--handleColor) !important;
}
.rs-handle.rs-focus {
  border: 11px solid #fff;
}
</style>
